<template>
  <div class="page_container">
    <!-- 静态图片 -->
    <div class="about_banner">
      <img src="../../assets/image/about/prize.jpg" alt />
    </div>
    <!-- 导航条 -->
    <div class="about_nav">
      <div class="about_nav_box">
        <ul class="about_nav_detail">
          <li>
            <router-link to="/about">公司简介</router-link>
          </li>
          <li>
            <router-link to="/prize">荣誉资质</router-link>
          </li>
          <li>
            <router-link to="/join">加入我们</router-link>
          </li>
          <li>
            <router-link to="/contact">联系我们</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- 荣誉资质展示 -->
    <div class="prize_box">
      <div class="prize_details clearfix">
        <div class="prize_picture_box left">
          <div class="prize_picture">
            <img src="../../assets/image/qualification/ISO14001.jpg" alt />
          </div>
          <p class="prize_title">ISO14001</p>
        </div>
        <div class="prize_picture_box left">
          <div class="prize_picture">
            <img src="../../assets/image/qualification/ISO9001.jpg" alt />
          </div>
          <p class="prize_title">ISO9001</p>
        </div>
        <div class="prize_picture_box left">
          <div class="prize_picture">
            <img src="../../assets/image/qualification/BlackHole.jpg" alt />
          </div>
          <p class="prize_title">BlackHole三维引擎插件软件</p>
        </div>
        <div class="prize_picture_box left">
          <div class="prize_picture">
            <img src="../../assets/image/qualification/dialog.jpg" alt />
          </div>
          <p class="prize_title">施工日志在线管理软件</p>
        </div>
        <div class="prize_picture_box left">
          <div class="prize_picture">
            <img src="../../assets/image/qualification/3WEI.jpg" alt />
          </div>
          <p class="prize_title">三维图形网络浏览软件</p>
        </div>
        <div class="prize_picture_box left">
          <div class="prize_picture">
            <img src="../../assets/image/qualification/IFC .jpg" alt />
          </div>
          <p class="prize_title">IFC 数据转换程序软件</p>
        </div>
        <div class="prize_picture_box left">
          <div class="prize_picture">
            <img src="../../assets/image/qualification/ISO14001.jpg" alt />
          </div>
          <p class="prize_title">ISO14001</p>
        </div>
        <div class="prize_picture_box left">
          <div class="prize_picture">
            <img src="../../assets/image/qualification/ISO14001.jpg" alt />
          </div>
          <p class="prize_title">ISO14001</p>
        </div>
      </div>
      <div class="prize_pagination">
        <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prize',
  data () {
    return {
      total: 8
    }
  }
}
</script>

<style>
</style>
